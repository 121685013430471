body {
  max-width: 100vw;
  overflow-x: hidden;
}
.no-scroll { overflow: hidden; }

.link {
  border-color: currentColor;
  @apply
    inline-block
    border-b
    leading-4
    /* transition-all duration-300 */
    hover:border-dashed
}
.contact-link {
  position: relative;
}
.contact-link:after {
  content: '';
  display: block;
  width: 224px; height: 185px;
  position: absolute; top: calc(50% - 10px); left: calc(50% - 10px);
  transform: translateX(-50%) translateY(-50%);
  background: url('../../assets/img/contact-decoration.svg') no-repeat;
  background-size: 100% 100%;
}
footer a {
  @apply
    inline-block
    leading-5
}
footer a:hover {
  @apply
  hover:border-dashed
  border-b border-JM-green
}

.cta {
  line-height: 4rem;
  @apply
    block
    rounded-full
    bg-black
    h-16
    text-white
    transition-all
    duration-500
}
.cta:hover { background: #000; }
.cta--white {
  @apply
    bg-white
    text-black
}
.cta--white:hover { @apply bg-gray-50 }
.cta--inline {
  @apply
    inline-block
    px-9
}

.highlighted {
  position: relative;
  display: inline-flex;
  margin-right: .25em;
}
.highlighted:before {
  display: block;
  content: '';
  width: calc(100% + .5em); height: calc(100% + .1em);
  position: absolute; top: 0; left: -.25em;
  padding: 0 60px;
  background-color: #fff;
  border-radius: .4em;
  z-index: -1;
}
.highlighted--green:before { background-color: #90FFA2; }

#hero h2 { position: relative; z-index: 0; }
#hero #big-logo,
#hero > svg {
  position: absolute; bottom: 0; left: 0;
  transform: translateX(-47%) translateY(47%);
  z-index: -1;
}

#conception #workshop ul li {
  background: url('../../assets/img/check.svg') no-repeat;
  background-position: left 0 top 4px;
}

#cooperate {
  background: url('../../assets/img/birds.jpg') no-repeat;
  background-position: center bottom 95px;
  background-size: 1854px 380px;
}

.surrounded {
  position: relative;
}
.surrounded:after {
  content: '';
  display: block;
  width: 220%; height: 220%;
  position: absolute; top: calc(50% - 5px); left: calc(50% - 10px);
  transform: translateX(-50%) translateY(-50%);
  background: url('../../assets/img/quote-surround.svg') no-repeat;
  background-size: 100% 100%;
  z-index: -1;
}

#eco-block ul li:nth-child(even) { flex: 60%; }
#eco-block ul li:nth-child(odd) { flex: 40%; }
#eco-block ul li a { display: block; }
#eco-block ul li a:hover { border: none !important; }

@media screen and (max-width: 1280px) {
  #hero {
    width: 100vw; overflow: hidden;
  }
  .surrounded:after {
    width: 140%;
    top: 50%;
    left: calc(50% - 15px);
  }
  #cooperate { background-position: right bottom 95px; }
}
@media screen and (max-width: 1024px) {
  #hero #big-logo,
  #hero > svg {
    transform: translateX(-60%) translateY(60%);
  }
  #cooperate { background: none; }
}
@media screen and (max-width: 768px) {
  #header.open {
    width: 100vw; height: 100vh;
    @apply
      bg-JM-green
  }
  #header.open .open-menu { display: none; }
  #header.open .close-menu { display: block; }
  #header.open #header-listed {
    display: block;
    position: absolute; top: 100px; left: 50%;
    transform: translateX(-50%);
    text-align: center;
  }
  #header.open #header-listed li {
    display: block;
    margin-bottom: 25px;
  }
  .contact-link:after { display: none; }
}
@media screen and (max-width: 640px) {
  #hero #big-logo,
  #hero > svg {
    bottom: 0; right: 0; left: 0;
    transform: translateX(-20%) translateY(65%) scale(.7);
  }
}

@media screen and (max-width: 420px) {
  .link-on-mobile {
    border-color: currentColor;
    background: none;
    padding: 0;
    height: auto;
    @apply
      rounded-none
      inline-block
      border-b
      leading-5
      text-black
      hover:border-dashed
  }
}

@media screen and (max-width: 380px) {
  #eco-block ul li:nth-child(even),
  #eco-block ul li:nth-child(odd) { flex: 100%; margin-bottom: 10px; }
  #eco-block ul li span { margin-right: 0; }
  #footer-logo { width: 140px; }
}

@media screen and (max-width: 340px) {
  .text-4xl { font-size: 1.8rem; line-height: 2.4rem; }
}